import "./App.css";
import ThreeGallery from "./components/ThreeGallery/threeGallery";
import UI from "./components/Ui/UI";

function App() {
  return (
    <div className="App">
      <ThreeGallery />
      <UI></UI>
    </div>
  );
}

export default App;
